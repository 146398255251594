@use '@angular/material' as mat;

@include mat.core();

$md-primary-san-cristobal: (
    50 : #e4f0f3,
    100 : #bcd9e1,
    200 : #8fc0ce,
    300 : #62a7ba,
    400 : #4094ab,
    500 : #1e819c,
    600 : #1a7994,
    700 : #166e8a,
    800 : #126480,
    900 : #0a516e,
    A100 : #a0e0ff,
    A200 : #6dcfff,
    A400 : #3abfff,
    A700 : #20b6ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-secondary-san-cristobal: (
    50 : #f8f2e1,
    100 : #eedeb4,
    200 : #e2c882,
    300 : #d6b150,
    400 : #cea12b,
    500 : #c59005,
    600 : #bf8804,
    700 : #b87d04,
    800 : #b07303,
    900 : #a36101,
    A100 : #ffe8ce,
    A200 : #ffd09b,
    A400 : #ffb868,
    A700 : #ffac4e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$main-color: mat.get-color-from-palette($md-primary-san-cristobal, 500, 300);
$light-main-color: mat.get-color-from-palette($md-primary-san-cristobal, 400, 200);
$secondary-color: mat.get-color-from-palette($md-secondary-san-cristobal, 500, 300);
$secondary-text: mat.get-color-from-palette(mat.$grey-palette, 600);
$forty-color: #f6f4f7;
$grey-50: mat.get-color-from-palette(mat.$grey-palette, 50);
$grey-100: mat.get-color-from-palette(mat.$grey-palette, 100);
$grey-200: mat.get-color-from-palette(mat.$grey-palette, 200);
$grey-300: mat.get-color-from-palette(mat.$grey-palette, 300);
$grey-400: mat.get-color-from-palette(mat.$grey-palette, 400);
$grey-500: mat.get-color-from-palette(mat.$grey-palette, 500);
$grey-600: mat.get-color-from-palette(mat.$grey-palette, 600);
$grey-700: mat.get-color-from-palette(mat.$grey-palette, 700);
$grey-800: mat.get-color-from-palette(mat.$grey-palette, 800);
$grey-900: mat.get-color-from-palette(mat.$grey-palette, 900);

$yellow-color: #c59005;
$red-color: #ff1744;
$cancel-color: #f44336;
$light-blue-color: #99d3d7;

$requested-color: mat.get-color-from-palette(mat.$amber-palette, 600); // Amber600
$pending-color: mat.get-color-from-palette(mat.$amber-palette, 600); // Amber600
$confirmed-color: mat.get-color-from-palette(mat.$blue-palette, 500); // Blue500;
$processing-payment-color: mat.get-color-from-palette(mat.$green-palette, 200); // Green200;
$payed-color: mat.get-color-from-palette(mat.$green-palette, 700); // Green700;
$processing-refund-color: mat.get-color-from-palette(mat.$brown-palette, 200); // Brown200;
$refunded-color: mat.get-color-from-palette(mat.$brown-palette, 500); // Brown500;
$cancelled-color: mat.get-color-from-palette(mat.$red-palette, 500); // Red500;
$processing-cancelled-color: mat.get-color-from-palette(mat.$red-palette, 200); // Red200;
$expired-color: mat.get-color-from-palette(mat.$grey-palette, 700); // Gray700;
$consumed-color: mat.get-color-from-palette(mat.$teal-palette, 700); // Teal700;
$modified-color: mat.get-color-from-palette(mat.$blue-grey-palette, 500); // BlueGray500;
$processing-modification-color: mat.get-color-from-palette(mat.$blue-grey-palette, 500); // BlueGray200;

$app-light-primary: mat.define-palette($md-primary-san-cristobal, 500, 300, 100);
$app-light-accent: mat.define-palette($md-secondary-san-cristobal, 500, 300);
$app-light-warn: mat.define-palette(mat.$red-palette, 500);

$custom-typography: mat.define-typography-config(
  $font-family: Poppins
);

$app-light-theme: mat.define-light-theme((
  color: (
    primary: $app-light-primary,
    accent: $app-light-accent,
    warn: $app-light-warn,
  ),
  typography: $custom-typography
));

@include mat.all-component-themes($app-light-theme);

%icon-colors {
  &.primary {
    color: $main-color;
  }

  &.secondary {
    color: $secondary-color;
  }

  &.primary-text {
    color: $grey-900;
  }

  &.secondary-text {
    color: $secondary-text;
  }
}

.color-grey-100 {
  color: $grey-100;
}

.color-grey-200 {
  color: $grey-200;
}

.color-grey-300 {
  color: $grey-300;
}

.color-grey-400 {
  color: $grey-400;
}

.color-grey-500 {
  color: $grey-500;
}

.color-grey-600 {
  color: $grey-600 !important;
}

.color-grey-700 {
  color: $grey-700;
}

.color-grey-800 {
  color: $grey-800;
}

.color-grey-900 {
  color: $grey-900;
}

.background-color-primary-100{
  background-color: mat.get-color-from-palette(mat.$blue-palette, 50);
}

.background-color-warm-50{
  background: mat.get-color-from-palette($app-light-warn, 50) !important;
}

